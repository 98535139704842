.admin-main {
  background-image: url("../../images/IITH_full.jpeg");
  background-size: cover;
  min-height: 100vh;
  /* position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px; */
}

.admin-next {
  min-height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;
}

.dashboard-main {
  display: flex;
  justify-content: center;
  padding: 20px;
}

.dashboard-paper {
  padding: 20px;
}
