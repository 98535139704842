.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.main-div {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
  background-image: url("./images/IITH_full.jpeg");
  background-size: cover;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.admin-paper,
.main-paper,
.adminscan-paper {
  width: 50%;
}

@media only screen and (max-width: 1160px) {
  .admin-paper,
  .main-paper,
  .adminscan-paper {
    width: 60%;
  }
}

@media only screen and (max-width: 900px) {
  .admin-paper,
  .main-paper,
  .adminscan-paper {
    width: 70%;
  }
}

@media only screen and (max-width: 750px) {
  .admin-paper,
  .main-paper,
  .adminscan-paper {
    width: 80%;
  }
}

.dashboard-pie-div {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  height: 100%;
}

.dashboard-pie {
  margin-top: 16px;
}

@media only screen and (max-width: 850px) {
  .dashboard-pie {
    width: 50%;
  }
}

@media only screen and (max-width: 660px) {
  .admin-paper,
  .main-paper,
  .adminscan-paper {
    width: 90%;
  }
  .dashboard-pie-div {
    display: flex;
    flex-direction: column;
  }
  .dashboard-pie {
    width: 70%;
  }
}

@media only screen and (max-width: 500px) {
  .dashboard-pie {
    width: 100%;
  }
}

.adminbarcode-main {
  background-image: url("./images/IITH_full.jpeg");
  background-size: cover;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;
}

.l-items {
  font-family: "Roboto Slab", serif;
  display: flex !important;
  justify-content: center !important;
}

.l-item {
  font-family: "Roboto Slab", serif;
  font-weight: 600;
}
